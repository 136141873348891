import React, { useState, useRef } from 'react';
import { useDownloadTemplateQuery, useImportCreateProductsMutation, useGetAllProductsQuery } from '../../redux/api/productsApi';
import { toast } from 'react-hot-toast';

const ProductCreateImportExport = () => {
  const { data: templateData } = useDownloadTemplateQuery();
  const [importCreateProducts] = useImportCreateProductsMutation();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const { refetch: refetchProducts } = useGetAllProductsQuery();

  const handleDownloadTemplate = () => {
    if (templateData) {
      const url = window.URL.createObjectURL(templateData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'createProducts.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || selectedFile.type === 'application/vnd.ms-excel')) {
      setFile(selectedFile);
    } else {
      toast.error('Lütfen geçerli bir Excel dosyası seçin.');
      e.target.value = null;
    }
  };

 const handleImport = async () => {
  if (!file) {
    toast.error('Lütfen bir dosya seçin.');
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  try {
    const result = await importCreateProducts(formData).unwrap();
    if (result.success) {
      toast.success(result.message);
      setFile(null);
      if (fileInputRef.current) fileInputRef.current.value = null;
    } else {
      throw new Error(result.message || 'Ürün yükleme işlemi başarısız oldu.');
    }
  } catch (error) {
    toast.error(`Hata: ${error.message}`);
  }
};

  return (
    <div className="product-import-export">
      <h2>Ürün İçe Aktar / Dışa Aktar</h2>
      <button onClick={handleDownloadTemplate}>Şablonu İndir</button>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".xlsx, .xls" />
      <button onClick={handleImport}>Ürünleri Yükle</button>
    </div>
  );
};

export default ProductCreateImportExport;