import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllProductsQuery } from "../../redux/api/productsApi";

const Search = ({ categories }) => {
  const [keyword, setKeyword] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const { data } = useGetAllProductsQuery({ keyword });
  
  useEffect(() => {
    if (data?.products && keyword.length > 0) {
      const filteredSuggestions = data.products
        .filter(product => product.name.toLowerCase().includes(keyword.toLowerCase()))
        .slice(0, 5)
        .map(product => product.name);
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [data, keyword]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const submitHandler = (e, selectedKeyword) => {
    e.preventDefault();
    const searchTerm = selectedKeyword || keyword;
    if (searchTerm?.trim()) {
      navigate(`/store_products?keyword=${encodeURIComponent(searchTerm)}`);
    } else {
      navigate(`/store_products`);
    }
    setShowSuggestions(false);
    setKeyword(searchTerm); // Seçilen öneriyi input'a yaz
  };

  const handleKeyDown = (e) => {
    if (showSuggestions) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedSuggestionIndex(prevIndex =>
            prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedSuggestionIndex(prevIndex =>
            prevIndex > 0 ? prevIndex - 1 : -1
          );
          break;
        case 'Enter':
          e.preventDefault();
          if (selectedSuggestionIndex >= 0) {
            submitHandler(e, suggestions[selectedSuggestionIndex]);
          } else {
            submitHandler(e);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div ref={searchRef}>
      <form onSubmit={(e) => submitHandler(e, keyword)}>
        <div className="input-group">
          <input
            type="text"
            id="search_field"
            aria-describedby="search_btn"
            className="form-control"
            placeholder="Ürün ara..."
            name="keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowSuggestions(true)}
            autoComplete="off"
          />
          <button id="search_btn" className="btn" type="submit">
            <i className="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </form>
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={index === selectedSuggestionIndex ? 'selected' : ''}
              onClick={(e) => submitHandler(e, suggestion)}
              onMouseEnter={() => setSelectedSuggestionIndex(index)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;