import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../redux/api/authApi';
import { setUser, setIsAuthenticated } from '../../redux/features/userSlice';
import { initSocket, closeSocket, markAsRead, deleteNotification } from '../../services/socketService';
import Search from './Search';
import { useShowCurrentUserQuery } from '../../redux/api/userApi';
import { markNotificationAsRead } from '../../redux/features/NotificationSlace';
import ChatNotifications from '../ChatNotifications';
import { getChatNotifications } from '../../services/chatNotificationService';
import { setChatNotifications } from '../../redux/features/NotificationSlace';
import { useGetCompanyLogoQuery } from '../../redux/api/companyInfoApi';
import Loader from '../Loader';
import { FaShoppingCart } from 'react-icons/fa';



const Header = () => {
  const { isLoading } = useShowCurrentUserQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [logout] = useLogoutMutation();
  const { cartItems } = useSelector((state) => state.cart);
  const { notifications } = useSelector((state) => state.notification);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showChatNotifications, setShowChatNotifications] = useState(false);
  const { chatNotifications } = useSelector((state) => state.notification);
  

   // Logo için ayrı bir sorgu kullanın
  const { data: logoData, isLoading: logoLoading } = useGetCompanyLogoQuery();
  const logo = logoData?.logo;


  const initSocketCallback = useCallback(() => {
    if (user && user._id) {
      initSocket(user._id);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const loadChatNotifications = async () => {
        try {
          const notifications = await getChatNotifications();
          dispatch(setChatNotifications(notifications));
        } catch (error) {
          console.error('Chat bildirimleri yüklenirken hata oluştu:', error);
        }
      };
      loadChatNotifications();
      initSocket(user._id);
    }
    return () => {
      closeSocket();
    };
  }, [user, dispatch]);

  const logoutHandler = async () => {
    await logout();
    dispatch(setIsAuthenticated(false));
    dispatch(setUser(null));
    closeSocket();
    navigate('/');
  };

  const handleMarkAsRead = (id) => {
    dispatch(markNotificationAsRead(id));
    markAsRead(user._id, id);
  };

  const handleDeleteNotification = (id) => {
    deleteNotification(user._id, id);
  };

  const unreadCount = Array.isArray(notifications) ? notifications.filter(n => !n.isRead).length : 0;

  const showNotificationDropdown = user && (user.role === "admin" || user.role === "seller") && Array.isArray(notifications) && notifications.length > 0;
 if (isLoading || logoLoading) return <Loader />;

  return (
    <nav className="navbar row flex-wrap">
      <div className="col-12 col-md-2 ps-5 mb-2 mb-md-0">
        <div className="navbar-brand">
          <a href="/">
            {logo && (
              <img src={logo} alt="ShopIT Logo" className="img-fluid" />
            )}
          </a>
          <a href="/" style={{ marginLeft: '15px' }}>
            { /* Anka kuşu resmi için kontrol */ }
            <img 
              src="/images/anka_bird.png" 
              alt="Anka Bird" 
              style={{ height: '80px' }} 
              onError={(e) => { e.target.onerror = null; e.target.src="/images/default_anka_bird.png"; }} // Hata durumunda varsayılan resim
            />
          </a>
        </div>
      </div>
      <div className="col-12 col-md-5 mb-2 mb-md-0">
        <Search />
      </div>

      <div className="col-12 col-md-5 d-flex flex-wrap justify-content-center justify-content-md-end align-items-center">
        <Link to="/store_products" className="nav-link me-2 mb-2 mb-md-0" style={{ color: 'white' }}>
          <span>Ürünler</span>
        </Link>
        <Link to="/contact" className="nav-link me-2 mb-2 mb-md-0" style={{ color: 'white' }}>
          <span>İletişim</span>
        </Link>
      
        <Link to="/cart" className="nav-link me-2 mb-2 mb-md-0">
  <FaShoppingCart size={20} color="white" />
  <span className="ms-1" id="cart_count">{cartItems.length}</span>
</Link>

        {showNotificationDropdown && (
          <div className="notification-dropdown me-2 mb-2 mb-md-0">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              Bildirimler
              {unreadCount > 0 && <span className="notification-count">{unreadCount}</span>}
            </button>
            {showNotifications && (
              <ul className="notification-menu">
                {notifications.length > 0 ? (
                  notifications.map(notification => (
                    <li key={notification._id} className={`notification-item ${notification.isRead ? '' : 'unread'}`}>
                      {notification.message}
                      {!notification.isRead ? (
                        <button onClick={() => handleMarkAsRead(notification._id)} className="mark-read-btn">
                          Okundu
                        </button>
                      ) : (
                        <button onClick={() => handleDeleteNotification(notification._id)} className="delete-btn">
                          Sil
                        </button>
                      )}
                    </li>
                  ))
                ) : (
                  <li>Bildirim yok</li>
                )}
              </ul>
            )}
          </div>
        )}

        {user && (
          <div className="chat-notification-dropdown me-2 mb-2 mb-md-0">
            <button
              className="chat-notification-button"
              onClick={() => setShowChatNotifications(!showChatNotifications)}
            >
              Sohbet Bildirimleri
              {Array.isArray(chatNotifications) && chatNotifications.filter(n => !n.isRead).length > 0 && (
                <span className="chat-notification-badge">
                  {chatNotifications.filter(n => !n.isRead).length}
                </span>
              )}
            </button>
            {showChatNotifications && (
              <div className="chat-notification-menu">
                <ChatNotifications />
              </div>
            )}
          </div>
        )}
      
        {user ? (
          <div className="dropdown mb-2 mb-md-0">
            <button
              className="btn dropdown-toggle text-white"
              type="button"
              id="dropDownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <figure className="avatar avatar-nav">
                <img
                  src={user?.profilePic ? user?.profilePic?.url : "/images/default_avatar.jpg"}
                  alt="User Avatar"
                  className="rounded-circle"
                />
              </figure>
              <span>{user?.name}</span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropDownMenuButton">
              {user?.role === "admin" && (
                <Link className="dropdown-item" to="/admin/dashboard">Dashboard</Link>
              )}
              <Link className="dropdown-item" to="/me/orders">Siparişler</Link>
              <Link className="dropdown-item" to="/me/profile">Profil</Link>
              <Link className="dropdown-item" to="/chat" title='Admin ile gerçek zamanlı sohbet'>Sohbet</Link>
              <Link className="dropdown-item text-danger" onClick={logoutHandler} to="/">Çıkış</Link>
            </div>
          </div>
        ) : !isLoading && (
          <Link to="/login" className="btn mb-2 mb-md-0" id="login_btn">Giriş Yap</Link>
        )}
      </div>
    </nav>
  );
}

export default Header;