import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPriceQueryParams } from '../../helpers/helpers';
import { useGetAllCategoriesQuery } from '../../redux/api/categoriesApi';
import { useGetAllBrandsQuery } from '../../redux/api/brandApi';
import StarRatings from 'react-star-ratings';

const Filters = ({ keyword }) => {
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: categoriesData } = useGetAllCategoriesQuery();
  const categories = categoriesData?.categories || [];
  
  const { data: brandsData } = useGetAllBrandsQuery();
  const brands = brandsData?.brands || [];

  useEffect(() => {
    setMin(searchParams.get('min') || '');
    setMax(searchParams.get('max') || '');

    const brandParams = searchParams.getAll('brand');
    setSelectedBrands(brandParams);

    const categoryParams = searchParams.getAll('category');
    setSelectedCategories(categoryParams);

    const ratingsParams = searchParams.getAll('ratings');
    setSelectedRatings(ratingsParams);
    
    console.log('Updated Search Params in useEffect:', searchParams.toString());
  }, [searchParams]);

  const handleButtonClick = (e) => {
    e.preventDefault();
    
    const updatedSearchParams = getPriceQueryParams(searchParams, 'min', min);
    getPriceQueryParams(updatedSearchParams, 'max', max);

    // URL'yi güncelle
    const path = window.location.pathname + "?" + updatedSearchParams.toString();
    console.log('Final Path on Submit:', path);
    navigate(path);
  };

  const handleClick = (checkbox) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (checkbox.checked) {
      // Eğer checkbox seçildiyse, diğer tüm markaları veya kategorileri kaldır
      newSearchParams.delete(checkbox.name); // Önce mevcut markayı veya kategoriyi kaldır
      newSearchParams.append(checkbox.name, checkbox.value); // Yeni markayı veya kategoriyi ekle
    } else {
      const values = newSearchParams.getAll(checkbox.name);
      const updatedValues = values.filter(value => value !== checkbox.value);
      newSearchParams.delete(checkbox.name);
      updatedValues.forEach(value => newSearchParams.append(checkbox.name, value));
    }

    // Keyword'ü güncelle
    newSearchParams.set('keyword', keyword);

    // URL'yi güncelle
    setSearchParams(newSearchParams);
    
    console.log('Updated Path:', window.location.pathname + "?" + newSearchParams.toString());
    navigate(window.location.pathname + "?" + newSearchParams.toString());
  };

  const defaultCheckHandler = (checkboxType, checkboxValue) => {
    return searchParams.getAll(checkboxType).includes(checkboxValue);
  };

  return (
    <div className="filters">
      <h3>Filtrele</h3>
      <hr />
      <h5 className="filter-heading mb-3">Fiyat</h5>
      <form id="filter_form" className="px-2" onSubmit={handleButtonClick}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="az"
              value={min}
              onChange={(e) => setMin(e.target.value)}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="çok"
              value={max}
              onChange={(e) => setMax(e.target.value)}
            />
          </div>
          <div className="col">
            <button type="submit" className="btn btn-primary">Git</button>
          </div>
        </div>
      </form>
      <hr />
      <h5 className="mb-3">Kategori</h5>
      <div className="form-check">
        {categories.map(category => (
          <div key={category._id}>
            <input
              className="form-check-input"
              type="checkbox"
              name="category"
              id={`check-${category._id}`}
              value={category._id}
              checked={defaultCheckHandler('category', category._id)}
              onChange={(e) => handleClick(e.target)}
            />
            <label className="form-check-label" htmlFor={`check-${category._id}`}>
              {category.name}
            </label>
          </div>
        ))}
      </div>
      <hr />
      <h5 className="mb-3">Marka</h5>
      <div className="form-check">
        {brands.map(brand => (
          <div key={brand._id}>
            <input
              className="form-check-input"
              type="checkbox"
              name="brand"
              id={`check-brand-${brand._id}`}
              value={brand._id}
              checked={defaultCheckHandler('brand', brand._id)}
              onChange={(e) => handleClick(e.target)}
            />
            <label className="form-check-label" htmlFor={`check-brand-${brand._id}`}>
              {brand.name}
            </label>
          </div>
        ))}
      </div>
      <hr />
      <h5 className="mb-3">Puan Yüksekliği</h5>
      {[5, 4, 3, 2, 1].map((rating) => (
        <div className="form-check" key={rating}>
          <input
            className="form-check-input"
            type="checkbox"
            name="ratings"
            id={`check-rating-${rating}`}
            value={rating}
            checked={defaultCheckHandler('ratings', rating.toString())}
            onChange={(e) => handleClick(e.target)}
          />
          <label className="form-check-label" htmlFor={`check-rating-${rating}`}>
            <StarRatings
              rating={rating}
              starRatedColor="#ffb829"
              numberOfStars={5}
              name='rating'
              starDimension="21px"
              starSpacing="1px"
            />
          </label>
        </div>
      ))}
    </div>
  );
};

export default Filters;