import React, { useEffect } from 'react';
import MetaData from './layout/MetaData';
import { useGetAllProductsQuery } from '../redux/api/productsApi';
import ProductItem from './product/ProductItem';
import Loader from './Loader';
import toast from 'react-hot-toast';
import CustomPagination from './layout/CustomPagination';
import { useSearchParams } from "react-router-dom";
import Filters from './layout/Filters';

const Home = () => {
  let [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";
  const page = searchParams.get('page') || 1;
  const params = { page, keyword }
  const min = searchParams.get('min') || 0;
  const max = searchParams.get('max') || 0;
  const category = searchParams.get('category') || '';
  const ratings = searchParams.get('ratings') || 0;
  const brand = searchParams.getAll('brand');

  min !== 0 && (params.min = min);
  max !== 0 && (params.max = max);
  category !== '' && (params.category = category);
  ratings !== 0 && (params.ratings = ratings);
  brand.length > 0 && (params.brand = brand);

  const { data, isLoading, error, isError } = useGetAllProductsQuery(params);
  
  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.msg || 'Bilinmeyen bir hata oluştu.');
    }
  }, [isError, error]);

  const columnSize = 4;

  if (isLoading) return <Loader />
  
  return (
    <>
      <MetaData title="En Kaliteli ve En Uygun Tesisat Ürünleri" />
      <div className="home row">
        <div className="col-12 col-md-3 mt-5"> {/* Filtreler için tam genişlik */}
          <Filters keyword={keyword} />
        </div>
        <div className="col-12 col-md-9">
          <h1 id="products_heading" className="text-secondary">
            {keyword ? `"${keyword}" aramasında "${data?.products.length}" adet ürün bulundu.` : "Son Eklenen Ürünler"}
          </h1>

          <section id="products" className="mt-5">
            <div className="row d-flex flex-wrap">
              {data?.products?.map((product) => (
                <ProductItem key={product._id} product={product} columnSize={columnSize} />
              ))}
            </div>
          </section>

          <CustomPagination resPerPage={data?.resPerPage} filteredProductsCount={data?.filteredProductsCount} />
        </div>
      </div>
    </>
  );
};

export default Home;

