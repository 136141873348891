import React from 'react';
import SideMenu from "./SideMenu";

const AdminLayout = ({ children }) => {
  
 const menuItems = [
    {
      name: "Admin Paneli",
      url: "/admin/dashboard",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "Ürün Ekle",
      url: "/admin/product/new",
      icon: "fas fa-plus",
    },
    {
      name: "Ürünler",
      url: "/admin/products",
      icon: "fab fa-product-hunt",
   },
   {
    name: "Ürün Fiyatlarını Güncelle",
    url: "/admin/product-import-export",
    icon: "fas fa-file-import",
   },
  {
    name: "Excel'den Ürün Kaydet",
    url: "/admin/product-create-import-export",
    icon: "fas fa-file-import",
  },
   {
    name: "Kategori Ekle",
    url: "/admin/category/new",
    icon: "fas fa-plus",
  },
  
    {
      name: "Kategoriler",
      url: "/admin/categories",
      icon: "fas fa-list",
   },
   {
    name: "Marka Ekle",
    url: "/admin/brand/new",
    icon: "fas fa-plus",
  },
    {
      name: "Markalar",
     url: "/admin/brands",
     icon: "fas fa-list",
   },
    
    {
      name: "Siparişler",
      url: "/admin/orders",
      icon: "fas fa-receipt",
   },
    {
      name: "Kullanıcılar",
      url: "/admin/users",
      icon: "fas fa-users",
   },
    {
      name: "Yorumlar",
      url: "/admin/reviews",
      icon: "fas fa-comment-dots",
    },
    {
      name: "Iyzipay Bilgileri",
      url: "/admin/iyzipay-info",
      icon: "fas fa-cog",
   },
   {
    name: "Şirket Bilgileri",
    url: "/admin/company-info",
    icon: "fas fa-cog",
   },
   {
    name: "E-posta Yapılandırması",
    url: "/admin/email-config",
    icon: "fas fa-cog",
   },
   
  ];

  return (
    <div>
      <div className="mt-2 mb-4 py-4">
        <h2 className="text-center fw-bolder">Admin Paneli</h2>
      </div>

     
        <div className="row justify-content-around">
          <div className="col-12 col-lg-3">
            <SideMenu menuItems={menuItems} />
          </div>
          <div className="col-12 col-lg-8 user-dashboard">{children}</div>
        </div>
      </div>
   
  );
};

export default AdminLayout;